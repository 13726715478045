
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { useI18n } from "vue-i18n";
import mixin from "@/mixins";
import {
  formatUtcDate,
  commonBackToList,
} from "@/core/directive/function/common";
import {
  getKycCommonTypeParams,
  getKycConfirmationTypeParams,
} from "@/core/directive/function/kyc";
import { KycType } from "@/core/directive/type/kyc";
import { settlementEntityOverviewData } from "@/core/directive/interface/kyc";
import { ApiKyc } from "@/core/api";
import _ from "lodash";

export default defineComponent({
  name: "settlement-entity-retailer-detail",
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();
    const { showServerErrorMsg } = mixin();

    const loading = ref(true);
    const baseData = ref(
      Object.assign({}, _.cloneDeep(settlementEntityOverviewData))
    );

    const getShowInfo = async () => {
      const { data } = await ApiKyc.getSettlementEntityOverview({
        id: route.params.id,
      });
      if (data) {
        baseData.value = Object.assign({}, baseData.value, data.data);
      } else {
        showServerErrorMsg(data);
      }
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getShowInfo()])
        .then(() => {
          loading.value = false;
          MenuComponent.reinitialization();
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const goPath = computed(() => {
      return getKycConfirmationTypeParams.value.routerUrl;
    });

    const backToList = () => {
      commonBackToList(router, goPath.value);
    };

    onMounted(() => {
      getFormInfo();
    });

    return {
      loading,
      baseData,
      KycType,
      getKycCommonTypeParams,
      getKycConfirmationTypeParams,
      formatUtcDate,
      goPath,
      getFormInfo,
      backToList,
    };
  },
});
