/*
 * @Author: Nick juntong.wu@travel-easy.com
 * @Date: 2024-08-23 19:44:31
 * @LastEditors: Nick juntong.wu@travel-easy.com
 * @LastEditTime: 2024-08-30 18:11:12
 * @FilePath: \settlement-frontend\src\core\directive\interface\kyc.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { CommonDateType, NumberOrString } from "@/core/directive/type/common";
import { entityStatus } from "@/core/directive/type/kyc";

/**
 * KYC配置项
 */

export interface KycCommonConfig {
  entityType: string;
  applicationScenarios: string;
  getContactViewApi: any;
  getContactStateAddressApi: any;
  getContactEditableApi: any;
  addContactApi: any;
  updateContactApi: any;
  updateContactIdentificationApi: any;
  updateContactAdditionalApi: any;
  deleteContactApi: any;
  getRegistrationViewApi: any;
  getRegistrationStateAddressApi: any;
  getRegistrationEditableApi: any;
  addRegistrationApi: any;
  updateRegistrationApi: any;
  updateRegistrationIdentificationApi: any;
  updateRegistrationAdditionalApi: any;
  deleteRegistrationApi: any;
}

export interface KycVerificationConfig {
  routerUrl: string;
}

export interface KycConfirmationConfig {
  routerUrl: string;
}

export interface SettlementEntityConfig {
  routerUrl: string;
  getSettlementEntityListApi: any;
}

/**
 * 主页列表数据类型
 */

export interface KycVerificationListItem {
  company_name: string;
  firstname: string;
  id: number;
  last_update_at: CommonDateType;
  lastname: string;
  type: string;
}

export interface KycConfirmationListItem {
  company_name: string;
  firstname: string;
  id: number;
  last_update_at: CommonDateType;
  lastname: string;
  type: string;
}

/**
 * 详情展示数据类型
 */

export interface KycCommonMetaInfo {
  id: NumberOrString;
  created_at: CommonDateType;
  updated_at: CommonDateType;
  created_by: string;
  updated_by: string;
}

const kycCommonMetaData: KycCommonMetaInfo = {
  id: "",
  created_at: "",
  updated_at: "",
  created_by: "",
  updated_by: "",
};

export interface KycCommonAddress {
  country: string;
  province: string;
  city: string;
  district: string;
  address1: string;
  address2: string;
  postcode: string;
  country_code?: string;
  city_id?: NumberOrString;
  district_id?: NumberOrString;
  province_id?: NumberOrString;
}

const kycCommonAddressData: KycCommonAddress = {
  country: "",
  province: "",
  city: "",
  district: "",
  address1: "",
  address2: "",
  postcode: "",
};

export interface KycCommonStatus {
  state?: string;
  status?: string;
  status_text?: string;
  kyc_status?: string;
  kyc_status_text?: string;
  kyc_level?: NumberOrString;
}

const kycCommonStatusData: KycCommonStatus = {
  state: "",
  status: "",
  status_text: "",
  kyc_status: "",
  kyc_status_text: "",
  kyc_level: "",
};

export interface KycAdditionalInfo {
  email: string;
  website: string;
  area_code?: string;
  telephone?: string;
  fax: string;
}

export interface KycRejectInfo {
  operator_name?: string;
  comments?: string;
}

const kycRejectData: KycRejectInfo = {
  operator_name: "",
  comments: "",
};

const kycAdditionalData: KycAdditionalInfo = {
  email: "",
  website: "",
  area_code: "",
  telephone: "",
  fax: "",
};

export interface JuridicalContactIdentification
  extends KycCommonAddress,
    KycCommonStatus {
  company_name: string;
  kyc_reject_info?: KycRejectInfo;
}

export interface JuridicalContactInfo extends KycCommonMetaInfo {
  identification: JuridicalContactIdentification;
  additional: KycAdditionalInfo;
}

export interface NaturalContactIdentification
  extends KycCommonAddress,
    KycCommonStatus {
  firstname: string;
  lastname: string;
  area_code: string;
  telephone: string;
  kyc_reject_info?: KycRejectInfo;
}

export interface NaturalContactInfo extends KycCommonMetaInfo {
  identification: NaturalContactIdentification;
  additional: KycAdditionalInfo;
}

export type ContactIdentification = (
  | JuridicalContactIdentification
  | NaturalContactIdentification
) &
  Partial<JuridicalContactIdentification & NaturalContactIdentification>;

export interface ContactInfo extends KycCommonMetaInfo {
  identification: ContactIdentification;
  additional: KycAdditionalInfo;
}

export const juridicalContactInfoData: JuridicalContactInfo = {
  ...kycCommonMetaData,
  identification: {
    company_name: "",
    ...kycCommonAddressData,
    ...kycCommonStatusData,
  },
  additional: {
    ...kycAdditionalData,
  },
};

export const naturalContactInfoData: NaturalContactInfo = {
  ...kycCommonMetaData,
  identification: {
    firstname: "",
    lastname: "",
    area_code: "",
    telephone: "",
    ...kycCommonAddressData,
    ...kycCommonStatusData,
  },
  additional: {
    email: "",
    website: "",
    fax: "",
  },
};

export const ContactInfoData: ContactInfo = {
  ...kycCommonMetaData,
  identification: {
    company_name: "",
    firstname: "",
    lastname: "",
    area_code: "",
    telephone: "",
    ...kycCommonAddressData,
    ...kycCommonStatusData,
    kyc_reject_info: {
      ...kycRejectData,
    },
  },
  additional: {
    ...kycAdditionalData,
  },
};

export const ContactFormData: ContactInfo = {
  ...kycCommonMetaData,
  identification: {
    company_name: "",
    firstname: "",
    lastname: "",
    area_code: "",
    telephone: "",
    ...kycCommonAddressData,
    country_code: "",
    city_id: "",
    district_id: "",
    province_id: "",
  },
  additional: {
    ...kycAdditionalData,
  },
};

export interface JuridicalLegalIdentification
  extends KycCommonAddress,
    KycCommonStatus {
  registered_company_name: string;
  company_legal_form: string;
  company_registration_number: string;
  registration_authority: string;
  legal_representative_name: string;
  kyc_reject_info?: KycRejectInfo;
}

export interface JuridicalLegalInfo extends KycCommonMetaInfo {
  identification: JuridicalLegalIdentification;
  additional: KycAdditionalInfo;
  create_from_id?: boolean;
}

export interface NaturalLegalIdentification
  extends KycCommonAddress,
    KycCommonStatus {
  firstname: string;
  lastname: string;
  passport: string;
  birthday: string;
  data_of_issue: string;
  validity_period: string;
  id_card: string;
  id_card_validity_period: string;
  kyc_reject_info?: KycRejectInfo;
}

export interface NaturalLegalInfo extends KycCommonMetaInfo {
  identification: NaturalLegalIdentification;
  additional: KycAdditionalInfo;
  create_from_id?: boolean;
}

export type LegalIdentification = (
  | JuridicalLegalIdentification
  | NaturalLegalIdentification
) &
  Partial<JuridicalLegalIdentification & NaturalLegalIdentification>;

export interface LegalInfo extends KycCommonMetaInfo {
  identification: LegalIdentification;
  additional: KycAdditionalInfo;
  create_from_id?: boolean;
}

export const juridicalLegalInfoData: JuridicalLegalInfo = {
  ...kycCommonMetaData,
  identification: {
    registered_company_name: "",
    company_legal_form: "",
    company_registration_number: "",
    registration_authority: "",
    legal_representative_name: "",
    ...kycCommonAddressData,
    ...kycCommonStatusData,
  },
  additional: {
    ...kycAdditionalData,
  },
  create_from_id: false,
};

export const naturalLegalInfoData: NaturalLegalInfo = {
  ...kycCommonMetaData,
  identification: {
    firstname: "",
    lastname: "",
    passport: "",
    birthday: "",
    data_of_issue: "",
    validity_period: "",
    id_card: "",
    id_card_validity_period: "",
    ...kycCommonAddressData,
    ...kycCommonStatusData,
  },
  additional: {
    ...kycAdditionalData,
  },
  create_from_id: false,
};

export const legalInfoData: LegalInfo = {
  ...kycCommonMetaData,
  identification: {
    registered_company_name: "",
    company_legal_form: "",
    company_registration_number: "",
    registration_authority: "",
    legal_representative_name: "",
    firstname: "",
    lastname: "",
    passport: "",
    birthday: "",
    data_of_issue: "",
    validity_period: "",
    id_card: "",
    id_card_validity_period: "",
    ...kycCommonAddressData,
    ...kycCommonStatusData,
    kyc_reject_info: {
      ...kycRejectData,
    },
  },
  additional: {
    ...kycAdditionalData,
  },
  create_from_id: false,
};

export const legalFormData: LegalInfo = {
  ...kycCommonMetaData,
  identification: {
    registered_company_name: "",
    company_legal_form: "",
    company_registration_number: "",
    registration_authority: "",
    legal_representative_name: "",
    firstname: "",
    lastname: "",
    passport: "",
    birthday: "",
    data_of_issue: "",
    validity_period: "",
    id_card: "",
    id_card_validity_period: "",
    ...kycCommonAddressData,
    country_code: "",
    city_id: "",
    district_id: "",
    province_id: "",
  },
  additional: {
    ...kycAdditionalData,
  },
};

export interface WarehouseIdentification
  extends KycCommonAddress,
    KycCommonStatus {
  warehouse_name: string;
  kyc_reject_info?: KycRejectInfo;
}

export interface WarehouseInfo extends KycCommonMetaInfo {
  identification: WarehouseIdentification;
  additional: KycAdditionalInfo;
}

export const warehouseInfoData: WarehouseInfo = {
  ...kycCommonMetaData,
  identification: {
    warehouse_name: "",
    ...kycCommonAddressData,
    ...kycCommonStatusData,
    kyc_reject_info: {
      ...kycRejectData,
    },
  },
  additional: {
    ...kycAdditionalData,
  },
};

export const warehouseFormData: WarehouseInfo = {
  ...kycCommonMetaData,
  identification: {
    warehouse_name: "",
    ...kycCommonAddressData,
    country_code: "",
    city_id: "",
    district_id: "",
    province_id: "",
  },
  additional: {
    ...kycAdditionalData,
  },
};

export interface InventoryIdentification
  extends KycCommonAddress,
    KycCommonStatus {
  firstname: string;
  lastname: string;
  kyc_reject_info?: KycRejectInfo;
}

export interface InventoryInfo extends KycCommonMetaInfo {
  identification: InventoryIdentification;
  additional: KycAdditionalInfo;
}

export const inventoryInfoData: InventoryInfo = {
  ...kycCommonMetaData,
  identification: {
    firstname: "",
    lastname: "",
    ...kycCommonAddressData,
    ...kycCommonStatusData,
    kyc_reject_info: {
      ...kycRejectData,
    },
  },
  additional: {
    ...kycAdditionalData,
  },
};

export const inventoryFormData: InventoryInfo = {
  ...kycCommonMetaData,
  identification: {
    firstname: "",
    lastname: "",
    ...kycCommonAddressData,
    country_code: "",
    city_id: "",
    district_id: "",
    province_id: "",
  },
  additional: {
    ...kycAdditionalData,
  },
};

export interface BillingAddressIdentification
  extends KycCommonAddress,
    KycCommonStatus {
  registered_company_name: string;
  company_legal_form: string;
  firstname: string;
  lastname: string;
  kyc_reject_info?: KycRejectInfo;
}

export interface BillingAddressInfo extends KycCommonMetaInfo {
  identification: BillingAddressIdentification;
}

export const billingAddressInfoData: BillingAddressInfo = {
  ...kycCommonMetaData,
  identification: {
    firstname: "",
    lastname: "",
    registered_company_name: "",
    company_legal_form: "",
    ...kycCommonAddressData,
    ...kycCommonStatusData,
    kyc_reject_info: {
      ...kycRejectData,
    },
  },
};

export const billingAddressFormData: BillingAddressInfo = {
  ...kycCommonMetaData,
  identification: {
    firstname: "",
    lastname: "",
    registered_company_name: "",
    company_legal_form: "",
    ...kycCommonAddressData,
    country_code: "",
    city_id: "",
    district_id: "",
    province_id: "",
  },
};

export interface FinancialIdentification
  extends KycCommonAddress,
    KycCommonStatus {
  holder_name: string;
  holder_registered_company_name: string;
  holder_company_legal_form: string;
  payment_institution: string;
  payment_institution_branch: string;
  account_number: string;
  bic_swift: string;
  third_party_platform: string;
  financial_type: string;
  kyc_reject_info?: KycRejectInfo;
}

export interface FinancialInfo extends KycCommonMetaInfo {
  identification: FinancialIdentification;
  additional: KycAdditionalInfo;
}

export const financialInfoData: FinancialInfo = {
  ...kycCommonMetaData,
  identification: {
    holder_name: "",
    holder_registered_company_name: "",
    holder_company_legal_form: "",
    payment_institution: "",
    payment_institution_branch: "",
    account_number: "",
    bic_swift: "",
    third_party_platform: "",
    financial_type: "",
    ...kycCommonAddressData,
    ...kycCommonStatusData,
    kyc_reject_info: {
      ...kycRejectData,
    },
  },
  additional: {
    ...kycAdditionalData,
  },
};

export const financialFormData: FinancialInfo = {
  ...kycCommonMetaData,
  identification: {
    holder_name: "",
    holder_registered_company_name: "",
    holder_company_legal_form: "",
    payment_institution: "",
    payment_institution_branch: "",
    account_number: "",
    bic_swift: "",
    third_party_platform: "",
    financial_type: "",
    ...kycCommonAddressData,
    country_code: "",
    city_id: "",
    district_id: "",
    province_id: "",
  },
  additional: {
    ...kycAdditionalData,
  },
};

export interface TaxIdentification extends KycCommonAddress, KycCommonStatus {
  tax_identification_type: string;
  registered_company_name: string;
  company_legal_form: string;
  vat_id: string;
  tax_id?: string;
  tax_registration_country: string;
  tax_registration_country_code?: string;
  local_tax_authority: string;
  local_tax_authority_id?: string;
  kyc_reject_info?: KycRejectInfo;
}

export interface TaxInfo extends KycCommonMetaInfo {
  identification: TaxIdentification;
  additional: KycAdditionalInfo;
}

export const taxInfoData: TaxInfo = {
  ...kycCommonMetaData,
  identification: {
    tax_identification_type: "",
    registered_company_name: "",
    company_legal_form: "",
    vat_id: "",
    tax_id: "",
    tax_registration_country: "",
    local_tax_authority: "",
    ...kycCommonAddressData,
    ...kycCommonStatusData,
    kyc_reject_info: {
      ...kycRejectData,
    },
  },
  additional: {
    ...kycAdditionalData,
  },
};

export const taxFormData: TaxInfo = {
  ...kycCommonMetaData,
  identification: {
    tax_identification_type: "",
    registered_company_name: "",
    company_legal_form: "",
    vat_id: "",
    tax_id: "",
    tax_registration_country: "",
    tax_registration_country_code: "",
    local_tax_authority: "",
    ...kycCommonAddressData,
    country_code: "",
    city_id: "",
    district_id: "",
    province_id: "",
  },
  additional: {
    ...kycAdditionalData,
  },
};

/**
 * 结算主体数据类型
 */
export interface SettlementEntityOverview {
  id: NumberOrString;
  type: string;
  created_at: CommonDateType;
  status: entityStatus;
  status_text: string;
  company_name?: string;
  firstname?: string;
  lastname?: string;
  country?: string;
  province?: string;
  city?: string;
  district?: string;
  address1?: string;
  address2?: string;
  postcode?: string;
  email?: string;
  website?: string;
  area_code?: string;
  telephone?: string;
  show_contact_identification: boolean;
}

export const settlementEntityOverviewData: SettlementEntityOverview = {
  id: "",
  type: "",
  created_at: null,
  status: entityStatus.Deactivated,
  status_text: "",
  company_name: "",
  firstname: "",
  lastname: "",
  country: "",
  province: "",
  city: "",
  district: "",
  address1: "",
  address2: "",
  postcode: "",
  email: "",
  website: "",
  area_code: "",
  telephone: "",
  show_contact_identification: true,
};
