
import { defineComponent } from "vue";
import KycConfirmationDetail from "@/components/page/kyc/kyc-confirmation/Detail.vue";

export default defineComponent({
  name: "kyc-confirmation-legal-entity-detail",
  components: {
    KycConfirmationDetail,
  },
  setup() {
    return {};
  },
});
